a:hover {
  text-decoration: none;
  color: #000000;
}

.underline,
.underline:hover {
  text-decoration: underline;
  color: #000000;
  font-weight: 500;
  text-decoration-color: #909090;
  text-decoration-thickness: 2px;
}
